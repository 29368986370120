.register {
    padding: 0px 55px !important;
    position: relative;
    box-shadow: none !important;
    border-radius: 0px !important;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    overflow: auto;
    height: 100vh;
    width: 100%;
}

.trial {
    font-family: 'Georgia', serif !important;
}

.form-reg-trial{
    /* min-height : 40rem; */
}

.PhoneInputInput{
    background-color : transparent !important;
    border: 0;
    color: rgb(4, 69, 175);
    padding: 4px 0 5px;
    font : inherit;
    letter-spacing: inherit;

}



.content {
    margin-top: 2em;
    min-width: 36rem;
}

.text-cond{
    /* margin-left: 25%;
    max-width: 50%; */
}

.step-terms {
    max-width: 36rem;
}


.step-terms p {
    color: rgb(4, 69, 175);
    font-weight: normal;
    text-align: justify;
}

.text-cond h3 {
    /* font-size: 1.5rem; */
    font-weight: normal;
}

.link-conditions{
    font-size: 15px !important;
    text-decoration: underline !important;
    color: black !important;
}

.opaqueButton {
    opacity: 0.5; /* Opacidad reducida para el botón deshabilitado */
}

.fields-form{
    min-height : 30rem;
}
.form-reg-trial h2{
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
.form-reg-trial h3{
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
.form-reg-trial h5{
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}



.input-reg{
    margin :  0rem 0rem 2rem 0rem;
    min-width: 100% !important;
}

.input-reg-2{
    margin :  0rem 0rem 0rem 0rem;
    min-width: 100% !important;
}

.input-reg-conditions{
    margin :  0rem 0rem 0rem 0rem;
    min-width: 100%;
}
.css-i44wyl{
    min-width: 100% I !important;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
    min-width: 100% !important;
}

.styled-input{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border-bottom: 1px solid rgb(4, 69, 175) !important;
}

.styled-input :hover{
    border-bottom: 1px solid rgb(4, 69, 175) !important;
}


.justify-content-between {
    justify-content: space-between;
}

.buttons-container {
    display: flex;
    justify-content: space-between; /* Si deseas que los botones estén uno a la izquierda y otro a la derecha */
    align-items: center;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: black !important;
    font-size: 1.2rem;
}
.buttons-form{
    display: flex ;
    align-items: center ;
}


.button-form{
    color: black !important;
    margin-right: 10px; /* Espacio entre el botón y la flecha, ajusta según sea necesario */
}

.align-right {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.align-left {
    margin-right: auto;
    display: flex ;
    align-items: center ;
}

.MuiButtonBase-root {
    display: flex;
    align-items: center;
}

.css-i4bv87-MuiSvgIcon-root{
    color: black;
}

.cmn-input-cls .MuiInputBase-root{
    min-width: 100%;
}

Button.submitReg {
    border-radius: 6.2px;
    border: 1.1px solid #0065FF !important;
    background: linear-gradient(134deg, #FFF -26.79%, #D1D1D1 230.75%) !important;
    box-shadow: 0px 3.1px 0px 0px #FFF inset, 3.1px 12.4px 37.2px 0px rgba(0, 0, 0, 0.16) !important;
    color: #151515 !important;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    margin: auto;
    padding: 13px 30px;
    text-transform: capitalize !important;
    margin-top: 15px;
  }


  .button-logo{
    background-color: white !important;
  }

  .price-table {
    border-radius: 20px !important;
    background-color: #4b5d88;
    padding : 20px;
}

.logo-register{
    max-width: 100px;
    max-height: 100px;
    margin: 20px;
}

.titles-reg{
    margin: 2rem;
}

.titles-reg h1 {
    font-weight : normal;
    font-size: 50px;
}

.titles-reg h2 {

    font-weight : normal;
    font-size: 40px;
}

.label-brand {
    font-family:  "Roboto","Helvetica","Arial",sans-serif;
    color: rgba(0, 0, 0, 0.6);
    font-size: 1rem;
}

.step-title {
    text-align: left;
}

.step-title h1 {
    font-weight : normal;
    text-align: left;
    margin : 0; 
    padding-top: 2rem;
}

.PhoneInput{
    min-width: 100%;
}

@media(max-width: 992px) {

   
    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
        min-width: 100% !important;
    }

    .fields-form{
        min-width: 400px !important;
    }


    .css-4dgcnv-MuiFormControl-root-MuiTextField-root>:not(style){
        min-width: 21rem !important;
    }

    .PhoneInput{
        min-width: 21rem !important;
    }
    
    .css-1t0t4lu > :not(style){
        min-width: 21rem !important;
    }

    .content{
        min-width: 100%;
    }
    

}

@media(max-width: 767px) {

    .register{
        height: 160vh;
        padding: 0px 20px !important;
    }

    .titles-reg h1{
        text-align: center;
    }

    .titles-reg h2{
        text-align: center;
    }

    .titles-reg p{
        text-align: center;
    }

    .css-i9gxme{
        max-width: 90% !important;
    }

    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
        min-width: 100% !important;
    }

    .css-1t0t4lu > :not(style){
        min-width: 21rem !important;
    }

    .css-4dgcnv-MuiFormControl-root-MuiTextField-root>:not(style){
        min-width: 21rem !important;
    }

    .PhoneInput{
        min-width: 21rem !important;
    }

    .form-reg-trial{
        max-width:  100% !important;
    }

    .css-1hjr3y7-MuiGrid-root{
    }

    .left-side-txt img {
        width : 100px !important;
    }

    .fields-form{
        min-width: 400px !important;
    }

    .titles-reg{
        margin: 0;
    }
    

    .content{
        min-width: 100%;
    }

}


@media(max-width: 420px) {

    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
        min-width: 100% !important;
    }

    .fields-form{
        min-width: 350px !important;
    }
    

    .content{
        min-width: 100%;
    }

}